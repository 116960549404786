const companies = [
    {
        id: 1,
        
        logo: "./img/companies/client-1.jpg"
    },
    {
        id: 2,
       
        logo: "./img/companies/client-2.jpg"
    },
    {
        id: 3,
       
        logo: "./img/companies/client-3.jpg"
    },
    {
        id: 4,
      
        logo: "./img/companies/client-4.jpg"
    },
    {
        id: 5,
      
        logo: "./img/companies/client-5.jpg"
    },
    {
        id: 6,
        
        logo: "./img/companies/client-7.jpg"
    },
    {
        id: 7,
      
        logo: "./img/companies/client-8.jpg"
    },
    {
        id: 8,
      
        logo: "./img/companies/client-9.jpg"
    },
    {
        id: 9,
       
        logo: "./img/companies/client-10.jpg"
    },
    {
        id: 10,
   
        logo: "./img/companies/client-22.png"
    },
    {
        id: 11,
        logo: "./img/companies/client-25.jpg"
    },
    {
        id: 12,
      
        logo: "./img/companies/client-50.jpg"
    },
    {
        id: 13,
       
        logo: "./img/companies/client-51.jpg"
    },
    {
        id: 14,
        
        logo: "./img/companies/client-52.jpg"
    },
    {
        id: 15,
       
        logo: "./img/companies/client-55.png"
    },
    {
        id: 16,
        
        logo: "./img/companies/client-70.png"
    },
    {
        id: 17,
       
        logo: "./img/companies/client-71 (1).png"
    },
    {
        id: 18,
      
        logo: "./img/companies/client-72.png"
    },
    {
        id: 19,
       
        logo: "./img/companies/client-73.jpg"
    },
    {
        id: 20,
      
        logo: "./img/companies/client-74.png"
    },
    {
        id: 21,
 
        logo: "./img/companies/client-75.png"
    },
    {
        id: 22,
   
        logo: "./img/companies/client-76.jpg"
    },
    {
        id: 23,
  
        logo: "./img/companies/client-77.jpeg"
    },
    {
        id: 24,
    
        logo: "./img/companies/client-78.png"
    },
    {
        id: 25,
    
        logo: "./img/companies/client-79.jpg"
    },
    {
        id: 26,
      
        logo: "./img/companies/client-72.png"
    }
   
];

export default companies;
