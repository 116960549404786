

const awrapper = [
    {
        id: 1,
        cover: "https://img.icons8.com/?size=100&id=108637&format=png&color=000000",
        count: "5220",
        title: "SUCCESS STORIES"
    },
    {
        id: 2,
        cover: "https://img.icons8.com/?size=100&id=uAkF8rMqKIyJ&format=png&color=000000",
        count: "450",
        title: "PROJECTS"
    },
    {
        id: 3,
        cover: "https://img.icons8.com/?size=100&id=114407&format=png&color=000000",
        count: "1462",
        title: "HOURS OF SUPPORT"
    },
    {
        id: 4,
        cover: "https://img.icons8.com/?size=100&id=cQjzgHcwdFo9&format=png&color=000000",
        count: "5220",
        title: "AWARDS"
    }
];

export default awrapper;
