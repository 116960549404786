import React from "react";
import { motion } from "framer-motion";
import FadeIn from "../../variants.js";
import "bootstrap/dist/css/bootstrap.min.css";

const Abouhome = () => {
  return (
    <div className="w-100 bg-light pb-5">
      <div className="container">
        <section className="py-5">
          <motion.div
            variants={FadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.9 }}
            className="d-flex justify-content-center mb-4"
          >
            <h1 className="fw-bold">Career Opportunities at Bright</h1>
          </motion.div>
          <div className="row">
            <motion.div
              variants={FadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="col-lg-6 mb-4"
            >
              <p>
                Bright InfoTech is India’s leading Software Training Institute
                that is trusted by organizations across the globe. We are an
                Institute offering a wide range of Master Courses in Data
                Software Engineering, Full Stack Developer, AWS, and Data
                Science and AI. We have been training students and transforming
                them into IT Professionals for a decade.
              </p>
              <p>
                The reason behind becoming one of the most preferred IT Training
                Institutes is that we offer global certifications along with
                career guidance and placement assistance. Besides that, the
                professors at our Institute are Experts from the Industry which
                gives an authentic touch to our up-to-date and interesting
                curriculum.
              </p>
              <p>
                We offer advanced IT Courses with Placements, making us stand
                out in the market. Bright InfoTech is one step ahead as we are
                offering IT jobs for freshers now. Our workplace is not just
                about completing daily tasks, but it is more about having a
                healthy as well as friendly environment for you to work in.
              </p>
              <p>
                Our platform offers a range of IT jobs for freshers, providing
                an opportunity to build your career in the Tech Industry.
              </p>
            </motion.div>
            <motion.div
              variants={FadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.9 }}
              className="col-lg-6 mb-4"
            >
              <div
                id="imageCarousel"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img
                      src="./img/image1.jpeg"
                      className="d-block w-100"
                      alt="Slide 1"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="./img/image2.jpeg"
                      className="d-block w-100"
                      alt="Slide 2"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="./img/image3.jpeg"
                      className="d-block w-100"
                      alt="Slide 3"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="./img/image4.jpeg"
                      className="d-block w-100"
                      alt="Slide 4"
                    />
                  </div>
                  <div className="carousel-item">
                    <img
                      src="./img/image5.jpeg"
                      className="d-block w-100"
                      alt="Slide 5"
                    />
                  </div>
                </div>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#imageCarousel"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#imageCarousel"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </motion.div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Abouhome;
