const ExampleCarouselImage = [
    {
        id: 1,
        src: "./img/workshop/workshop1.jpeg",
        title: "Title 1",
        desc: "Description 1"
    },
    {
        id: 2,
        src: "./img/workshop/workshop2.jpeg",
        title: "Title 2",
        desc: "Description 2"
    },
    {
        id: 3,
        src: "./img/workshop/workshop3.jpeg",
        title: "Title 3",
        desc: "Description 3"
    },
    {
        id: 4,
        src: "./img/workshop/workshop4.jpeg",
        title: "Title 4",
        desc: "Description 4"
    },
    {
        id: 5,
        src: "./img/workshop/workshop5.jpeg",
        title: "Title 5",
        desc: "Description 5"
    },
    {
        id: 6,
        src: "./img/workshop/workshop6.jpeg",
        title: "Title 6",
        desc: "Description 6"
    },
    {
        id: 7,
        src: "./img/workshop/workshop7.jpeg",
        title: "Title 7",
        desc: "Description 7"
    },
    {
        id: 8,
        src: "./img/workshop/workshop8.jpeg",
        title: "Title 8",
        desc: "Description 8"
    },
    {
        id: 9,
        src: "./img/workshop/workshop9.jpeg",
        title: "Title 9",
        desc: "Description 9"
    },
    {
        id: 10,
        src: "./img/workshop/workshop10.jpeg",
        title: "Title 10",
        desc: "Description 10"
    },
    {
        id: 11,
        src: "./img/workshop/workshop11.jpeg",
        title: "Title 11",
        desc: "Description 11"
    },
    {
        id: 12,
        src: "./img/workshop/workshop12.jpeg",
        title: "Title 12",
        desc: "Description 12"
    },
    {
        id: 13,
        src: "./img/workshop/workshop13.jpeg",
        title: "Title 13",
        desc: "Description 13"
    },
    {
        id: 14,
        src: "./img/workshop/workshop14.jpeg",
        title: "Title 14",
        desc: "Description 14"
    },
    {
        id: 15,
        src: "./img/workshop/workshop15.jpeg",
        title: "Title 15",
        desc: "Description 15"
    },
    {
        id: 16,
        src: "./img/workshop/workshop16.jpeg",
        title: "Title 16",
        desc: "Description 16"
    },
    {
        id: 17,
        src: "./img/workshop/workshop17.jpeg",
        title: "Title 17",
        desc: "Description 17"
    },
    {
        id: 18,
        src: "./img/workshop/workshop18.jpeg",
        title: "Title 18",
        desc: "Description 18"
    },
    {
        id: 19,
        src: "./img/workshop/workshop19.jpeg",
        title: "Title 19",
        desc: "Description 19"
    },
    {
        id: 20,
        src: "./img/workshop/workshop20.jpeg",
        title: "Title 20",
        desc: "Description 20"
    },
    {
        id: 21,
        src: "./img/workshop/workshop21.jpeg",
        title: "Title 21",
        desc: "Description 21"
    },
    {
        id: 22,
        src: "./img/workshop/workshop22.jpeg",
        title: "Title 22",
        desc: "Description 22"
    },
    {
        id: 23,
        src: "./img/workshop/workshop23.jpeg",
        title: "Title 23",
        desc: "Description 23"
    },
    {
        id: 24,
        src: "./img/workshop/workshop24.jpeg",
        title: "Title 24",
        desc: "Description 24"
    }
    ,
    {
        id: 25,
        src: "./img/workshop/workshop25.jpg",
        title: "Title 24",
        desc: "Description 24"
    }
    ,
    {
        id: 26,
        src: "./img/workshop/workshop26.jpg",
        title: "Title 24",
        desc: "Description 24"
    }
    ,
    {
        id: 27,
        src: "./img/workshop/workshop27.jpg",
        title: "Title 24",
        desc: "Description 24"
    }
]

export default ExampleCarouselImage;
