const placedstudentsdetails = [
    { id: 1, name: "Dinesh Kore", role: "Software Test Enginner", photo: "./img/placed students/s1.png" },
    { id: 2, name: "Amit Kale", role: "Python Automation Test Engineer", photo: "./img/placed students/s2.png" },
    { id: 3, name: "Minu Jadhav", role: "Flutter Developer", photo: "./img/placed students/s3.png" },
    { id: 4, name: "Devshree", role: "Flutter Developer;", photo: "./img/placed students/s4.png" },
    { id: 5, name: "Gautame Gaikwad", role: "Python Developer", photo: "./img/placed students/s5.png" },
    { id: 6, name: "Madhavi Chavan", role: "Python Automation Test Enginner", photo: "./img/placed students/s6.png" },
    { id: 7, name: "Komal pandhare", role: "MIS Executive", photo: "./img/placed students/s7.png" },
    { id: 8, name: "Shaikh Lal", role: "Admin Support", photo: "./img/placed students/s8.png" },
    { id: 9, name: "Shubhangi Tankale", role: "Software Test Enginnerr", photo: "./img/placed students/s9.png" },
    { id: 10, name: "Darshan Borse", role: ".NET Developer", photo: "./img/placed students/s10.png" },
    { id: 11, name: "Raghu Upade", role: "Manual Test Enginner", photo: "./img/placed students/s11.png" },
    { id: 12, name: "Girish", role: "Power-Bi Developer", photo: "./img/placed students/s12.png" },
    { id: 13, name: "Nikhil Kulkarne", role: "Test Enginner", photo: "./img/placed students/s13.png" },
    { id: 14, name: "Sunil Gard", role: "Manual Taster", photo: "./img/placed students/s14.png" },
    { id: 15, name: "Aishwarya Bhosle", role: "Software Developer", photo: "./img/placed students/s15.png" },
    { id: 16, name: "Nandkishor Deshmukh", role: "Software Enginner", photo: "./img/placed students/s16.png" },
    { id: 17, name: "Ritesh Pawar", role: "Manual Test Enginner", photo: "./img/placed students/s17.png" },
    { id: 18, name: "Jagruti Narhare", role: "Software Developer", photo: "./img/placed students/s18.png" },
    { id: 19, name: "Prianka Dhoke", role: "Software Test Enginner", photo: "./img/placed students/s19.png" },
    { id: 20, name: "Shital Manker", role: "Test Enginner", photo: "./img/placed students/s20.png" },
    { id: 21, name: "Samir Vdhkade", role: "Mobile App Developer", photo: "./img/placed students/s21.png" },
    { id: 22, name: "Ashwani Swami", role: "MIS Executive", photo: "./img/placed students/s22.png" },
    { id: 23, name: "Ramanand", role: "Executive", photo: "./img/placed students/s23.png" },
    { id: 23, name: "Ramanand", role: "Executive", photo: "./img/placed students/s23.png" },
    { id: 24, name: "Rajeh Jawale", role: "MIS Operator(Goverment)", photo: "./img/placed students/s24.png" },
    { id: 25, name: "Kamlesh Patil", role: "Software Developer ", photo: "./img/placed students/s25.png" },
    { id: 26, name: "Renuka Jadhav", role: "Software Test Enginner", photo: "./img/placed students/s26.png" },
    { id: 27, name: "Akshay Daswante", role: "Back Offilce Exective", photo: "./img/placed students/s27.png" },
    { id: 28, name: "Uttam Thakur", role: "Software Test Enginneer", photo: "./img/placed students/s28.png" },
    { id: 29, name: "Rushikesh Bhivate", role: "Software Enginner", photo: "./img/placed students/s29.png" },
    { id: 30, name: "Govind Wagalgave", role: "admin Support SQL,Linux", photo: "./img/placed students/s30.png" },
    { id: 31, name: "Amol Giri", role: ".Dot net Developer", photo: "./img/placed students/s31.png" },
    { id: 32, name: "Anil Kadam", role: "Power-Bi Developer", photo: "./img/placed students/s32.png" },
    { id: 33, name: "Chayya Mandale", role: "MIS Executive", photo: "./img/placed students/s33.png" },
    { id: 34, name: "Mangesh Pandhare", role: "Power-Bi Developer", photo: "./img/placed students/s34.png" },
    { id: 35, name: "Nikita Rayewar", role: "MIS Executive", photo: "./img/placed students/s35.png" },
    { id: 36, name: "Sagar Pigne", role: "MIS Executive", photo: "./img/placed students/s36.png" },
    { id: 37, name: "Rohit Darekar", role: "MIS Executive", photo: "./img/placed students/s37.png" },
    { id: 38, name: "Sayyad Fauizula", role: "MIS Executive", photo: "./img/placed students/s38.png" },
    { id: 39, name: "Bsawaraj Patil", role: "MIS Executive", photo: "./img/placed students/s39.png" },
    { id: 40, name: "Dnyaneshwar Chawan", role: "MIS Executive", photo: "./img/placed students/s40.png" },
    { id: 41, name: "Pooja Tode", role: "SQL Suppoet Enginner", photo: "./img/placed students/s41.png" },
    { id: 42, name: "Aarti Thorat", role: "Admin Support", photo: "./img/placed students/s42.png" },
    { id: 43, name: "Nandkishor Deshmukh", role: "MIS Executive", photo: "./img/placed students/s43.png" },
    { id: 44, name: "Prajkta Wadhi", role: "Power-Bi Developer", photo: "./img/placed students/s44.png" },
    { id: 45, name: "Dipak Bhujbal", role: "MIS Executive", photo: "./img/placed students/s45.png" },
    { id: 46, name: "Rohini Patil", role: "MIS Executive", photo: "./img/placed students/s46.png" },
    { id: 47, name: "Tukaram Surwase", role: "MIS Executive", photo: "./img/placed students/s47.png" },
    { id: 48, name: "Magar Pallavi", role: "MIS Executive", photo: "./img/placed students/s48.png" },
    { id: 49, name: "Tukaram Surwase", role: "MIS Executive", photo: "./img/placed students/s49.png" },
    { id: 50, name: "Chetan Dhage", role: "Power Bi Developer", photo: "./img/placed students/s50.png" },
    { id: 51, name: "Krushna Kumbhare", role: "Power Bi Developer", photo: "./img/placed students/s51.png" },
    { id: 52, name: "Shreyas Shrimanwar", role: "Power Bi Developer", photo: "./img/placed students/s52.png" },
    { id: 53, name: "Vaishnavi Rathod", role: "Power Bi Developer", photo: "./img/placed students/s53.png" },
    { id: 54, name: "Krishna", role: "Power Bi Developer", photo: "./img/placed students/s54.png" },
    { id: 55, name: "Sakshi ballamkhanr", role: "Power Bi Developer", photo: "./img/placed students/s55.png" }
];

export default placedstudentsdetails;
