

const Durationtime = [
    {
        id:1,
        title:"Duration:",
        logo :"https://img.icons8.com/?size=100&id=59760&format=png&color=000000",
        desc:"Job Ready in 6 Month"  
    },
    {
        id:2,
        title:"Case Studies:",
        logo :"https://img.icons8.com/?size=100&id=26d3JYwzPlUz&format=png&color=000000",
        desc:"15 +"  
    },
    {
        id:3,
        title:"Mode of Training:",
        logo :"https://img.icons8.com/?size=100&id=3656&format=png&color=000000",
        desc:"Classroom & Online"  
    },
]

export default Durationtime;