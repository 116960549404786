import React from 'react'
import AboutCard from './AboutCard'
import Ourteam from './OurTeam'
import Abouhome from "./abouhome"


const About = () => {
  return (
    <>
        <AboutCard />
        <Abouhome/>
        <Ourteam />
        
    </>
    
  )
}

export default About




