import React from "react";
import "./homecoursel.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const Homecoursel = () => {
  return (
    <div className="w-100">
      <section className="container">
        <div
          id="carouselExampleDark"
          className="carousel carousel-dark slide h-100"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="7"
              aria-label="Slide 8"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="8"
              aria-label="Slide 9"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="9"
              aria-label="Slide 10"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleDark"
              data-bs-slide-to="10"
              aria-label="Slide 11"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="2000">
              <img
                src="./img/Flutter.jpg"
                className="d-block w-100 img-fluid"
                height={500}
                alt="Flutter"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/merndev2.png"
                className="d-block w-100 img-fluid"
                height={500}
                alt="MERN"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/mis2.png"
                className="d-block w-100 img-fluid"
                height={500}
                alt="MIS"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/testing2.png"
                className="d-block w-100 img-fluid"
                height={500}
                alt="Testing"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/dotnet.png"
                className="d-block w-100 img-fluid"
                height={500}
                alt="Dotnet"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/Jira.jpg"
                className="d-block w-100 img-fluid"
                height={500}
                alt="Jira"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/PHP.png"
                className="d-block w-100 img-fluid"
                height={500}
                alt="PHP"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/Power BI.png"
                className="d-block w-100 img-fluid"
                height={500}
                alt="Power BI"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/SAP.jpg"
                className="d-block w-100 img-fluid"
                height={500}
                alt="SAP"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/AWS.jpg"
                className="d-block w-100 img-fluid"
                height={500}
                alt="AWS"
              />
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <img
                src="./img/Python.jpeg"
                className="d-block w-100 img-fluid"
                height={500}
                alt="Python"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Homecoursel;
