const companies2 = [
    {
        id: 1,
        logo: "./img/companies/client-52.jpg"
    },
    {
        id: 2,
        logo: "./img/companies/client-55.png"
    },
    {
        id: 3,
        logo: "./img/companies/client-70.png"
    },
    {
        id: 4,
        logo: "./img/companies/client-71 (1).png"
    },
    {
        id: 5,
        logo: "./img/companies/client-72.png"
    },
    {
        id: 6,
        logo: "./img/companies/client-73.jpg"
    },
    {
        id: 7,
        logo: "./img/companies/client-74.png"
    },
    {
        id: 8,
        logo: "./img/companies/client-75.png"
    },
    {
        id: 9,
        logo: "./img/companies/client-76.jpg"
    },
    {
        id: 10,
        logo: "./img/companies/client-77.jpeg"
    },
    {
        id: 11,
        logo: "./img/companies/client-78.png"
    },
    {
        id: 12,
        logo: "./img/companies/client-79.jpg"
    },
    {
        id: 13,
        logo: "./img/companies/client-72.png"
    }
];

export default companies2;
