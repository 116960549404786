import React from "react";
import Hero from "./hero/Hero";
import AboutCard from "../about/AboutCard";
import Contentserver from "../services/onlineCources";
import Formcard from "../common/form/form";
import Footer from "../common/footer/footer";
import Maplocation from "../contact/map";
import Formdata from "../contact/Contactcard";
import Slidercompanies from "../sliderpartners/slidercompanies";
import Workshops from "../workshop/workshops";
import Accordionsubjects from "../services/accordion";
import Backimg from "../services/backimg";
import Abouhome from "../about/abouhome";
import Homecoursel from "./homecoursel";
import Allstudentsslider from "../Placed Students/Allstudentsslider";

export const Home = () => {
  return (
    <div className="home-container">
      <div className="section">
        <Homecoursel />
      </div>
      <div className="section">
        <Abouhome />
      </div>
      <div className="section">
        <Backimg />
      </div>
      <div className="section">
        <Accordionsubjects />
      </div>
      <div className="section">
        <Allstudentsslider />
      </div>
      <div className="section">
        <Workshops />
      </div>
      <div className="section">
        <Slidercompanies />
      </div>
      <div className="section">
        <Formdata />
      </div>
      <div className="section">
        <Maplocation />
      </div>
      <div className="section">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
